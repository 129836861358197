<template>
  <div>
    <div>
      <v-container>
        <v-overlay :value="loading" opacity="0.8">
          <div>
            <v-progress-circular indeterminate></v-progress-circular>
          </div>
        </v-overlay>
        <v-btn text color="accent" @click="$router.go(-1)" class="mb-2">
          <v-icon left> mdi-arrow-left-thick </v-icon>
          Back
        </v-btn>
        <v-row>
          <v-col cols="12" md="8">
            <h1>Bills</h1>
            <p class="text-subtitle-1 font-weight-bold">
              Got questions? Call or Whatsapp {{ siteData.phone }}
            </p>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="search"
              label="Search services"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="searchServices.length == 0 && !loading">
            <v-card>
              <v-container>
                <v-card-title class="text-center"
                  >Service not Provided at the moment</v-card-title
                >
                <v-card-subtitle>
                  <b>"{{ search }}"</b> is not available at the
                  moment</v-card-subtitle
                >
              </v-container>
            </v-card>
          </v-col>
          <v-col
            v-else
            v-for="card in searchServices"
            :key="card._id"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                ripple
                :to="`/user/products/bills/${slugify(card.title)}/`"
                :elevation="hover ? '8' : '2'"
                rounded="lg"
                class="pa-5 text-center"
                :disabled="card.status === false"
              >
                <v-img contain :src="card.image" height="115"></v-img>
                <v-card-title class="text-center mx-auto">
                  <span
                    style="font-size: 16px"
                    class="mx-auto font-bold text-uppercase"
                    >{{ card.title }}</span
                  >
                </v-card-title>
                <v-card-subtitle v-if="card.status === false">
                  <span style="font-size: 14px">{{ card.message }}</span>
                </v-card-subtitle>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { slugify } from '../../../utils/helpers'

import airtimeImage from '../../../assets/img/bill/airtime.jpeg'
import bettingImage from '../../../assets/img/bill/betting.jpeg'
import cableImage from '../../../assets/img/bill/cable.jpeg'
import eleImage from '../../../assets/img/bill/electricity.jpeg'
import internetImage from '../../../assets/img/bill/internet.jpeg'

export default {
  data: () => ({
    search: '',
    services: [],
    slugify,
    loading: false
  }),
  created() {
    this.$store.dispatch('user/getSiteSettings')
    this.getAllGiftcard()
  },
  computed: {
    ...mapState({
      siteData: (state) => state.user.siteSettings
    }),
    searchServices() {
      if (this.search) {
        return this.services.filter((currency) =>
          currency.title
            .toString()
            .toLowerCase()
            .includes(this.search.toLowerCase())
        )
      }
      return this.services
    }
  },
  methods: {
    async getAllGiftcard() {
      this.loading = true
      const res = [
        {
          title: 'Airtime',
          status: this.siteData.airtimeFeatureAvailable,
          message: this.siteData.airtimeFeatureMessage,
          image: airtimeImage
        },
        {
          title: 'Internet Data',
          status: this.siteData.dataFeatureAvailable,
          message: this.siteData.dataFeatureMessage,
          image: internetImage
        },
        {
          title: 'Cable TV',
          status: this.siteData.cableFeatureAvailable,
          message: this.siteData.cableFeatureMessage,
          image: cableImage
        },
        {
          title: 'Electricity',
          status: this.siteData.electricityFeatureAvailable,
          message: this.siteData.electricityFeatureMessage,
          image: eleImage
        },
        {
          title: 'Bettings Top-up',
          status: this.siteData.bettingFeatureAvailable,
          message: this.siteData.bettingFeatureMessage,
          image: bettingImage
        }
        // {
        //   title: 'Convert Airtime',
        //   status: true
        // }
      ]
      this.services = res.sort((a, b) => {
        const textA = a.title.toLowerCase()
        const textB = b.title.toLowerCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
      })
      this.loading = false
    }
  }
}
</script>

<style></style>
